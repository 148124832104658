<template>
    <div>
      <tablerof></tablerof>
    </div>
  </template>
  
  <script>
  import tablerof from "@/components/ecologist/registerOfFacilities/table";
  export default {
    name: "tableView",
    components: { tablerof },
  };
  </script>
  
  <style scoped></style>