<template>
    <div>
        <el-table stripe :data="daterof">
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="" prop="">
                <template slot-scope="item">
                    {{ item.row.name2+"-"+item.row.name }}
                </template>
            </el-table-column>
            <el-table-column label="" prop="">
                <template slot-scope="item">
                    <el-button
                    :disabled="item.row.id!=0"
                    @click="setRegWaste(item.row)"
                    type="primary"
                    style="float: left"
                    >Отметить
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import { AXIOS } from "@/AXIOS/http-common";
export default {
  name: "tablerof",
  components: {},
  data() {
    return {
        daterof:[{
            id:null,
            name:null,
            name2:null,
        }],
    }
  },
  methods: 
  {
    async setRegWaste(raw)
    {
        await AXIOS.get("data-entry/reg/set?wastecode="+raw.name2).then(()=>
            {
                AXIOS.get("data-entry/reg/get").then((response)=>
                {
                    this.daterof=response.data;
                });
            });
    }
  },
  async mounted()
  {
    await AXIOS.get("data-entry/reg/get").then((response)=>
    {
        this.daterof=response.data;
    });
  }
}
</script>
